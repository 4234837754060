<template>
  <div class="row" :class="{
    'q-pt-lg q-px-lg': gutters
  }">
    <!-- Notif Dialog -->
    <q-dialog
      v-model="dialog.show"
    >
      <q-card style="width: 300px">
        <q-card-section>
          <div>{{ dialog.message }}</div>
        </q-card-section>

        <q-card-actions align="right" class="bg-white text-teal">
          <q-btn flat size="sm" label="Close" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <!-- Dialog Confirm -->
    <dialog-confirm v-model="dialogConfirm.show" :title="dialogConfirm.title" :body="dialogConfirm.body" @onYes="onDelete" />
    <!-- Dialog Preview -->
    <q-dialog v-model="dialogPreview.show">
      <q-card style="width: 1280px; max-width: 90vw;">
        <q-card-section class="row items-center">
          <q-img
            v-if="dialogPreview.type === 'image'"
            :src="dialogPreview.source"
            :ratio="16/9"
            spinner-color="primary"
            spinner-size="82px"
            fit="contain"
          />
          <video
            v-if="dialogPreview.type === 'video'"
            :src="dialogPreview.source"
            style="width:100%"
            controls
          />
          <audio
            v-if="dialogPreview.type === 'audio'"
            :src="dialogPreview.source"
            style="width:100%"
            controls
          />
        </q-card-section>
      </q-card>
    </q-dialog>
    <!-- Form Dialog -->
    <q-dialog
      v-model="dialogForm.show"
    >
      <form-projectMedia
        :mode="dialogForm.mode"
        :mainId="mainId"
        :data="dialogForm.data"
        @onCancel="dialogForm.show = false"
        @postSubmit="dialogForm.show = false"
      />
    </q-dialog>
    <!-- Data Table -->
    <q-table
      class="sticky-action full-width"
      title="Project Medias"
      :rows="rows"
      :columns="columns"
      :filter="filter"
      row-key="id"
    >
      <template #top-left>
        <q-btn-group>
          <q-btn color="secondary" size="sm" @click="router.go(-1)">Back</q-btn>
          <q-btn color="primary" size="sm" @click="onCreate">New</q-btn>
        </q-btn-group>
        <span class="text-h6 q-ml-sm">Project Medias of {{ projectTitle }}</span>
      </template>
      <template v-if="filterLocation === 'top'" #top-right>
        <q-input dense debounce="300" color="primary" v-model="filter">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>
      <template v-if="filterLocation === 'bottom'" #bottom>
        <q-input dense debounce="300" color="primary" v-model="filter">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>
      <template #body-cell-url="{ row }">
        <td class="cursor-pointer" @click="onSelectMedia(row)">
          {{ row.url }}
        </td>
      </template>
      <template #body-cell-action="{ row }">
        <td>
          <q-btn-group>
            <q-btn color="secondary" size="xs" round icon="edit" @click="onSelectRow('edit', row)">
              <q-tooltip>
                Edit
              </q-tooltip>
            </q-btn>
            <q-btn color="red" size="xs" round icon="delete" @click="onSelectRow('delete', row)">
              <q-tooltip>
                Delete
              </q-tooltip>
            </q-btn>
          </q-btn-group>
        </td>
      </template>
    </q-table>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import { computed, getCurrentInstance, watchEffect } from '@vue/runtime-core'
import { formatDate, limitText, capitalize } from '../utils/functions'
import formProjectMedia from '../components/formProjectMedia.vue'
import { useRoute, useRouter } from 'vue-router'

export default {
  props: {
    gutters: {
      type: Boolean,
      default: true
    },
    filterLocation: {
      type: String,
      default: 'top'
    }
  },
  components: { formProjectMedia },
  setup (props) {
    const instance = getCurrentInstance()
    const self = instance.appContext.config.globalProperties
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const dialog = ref({
      show: false,
      message: ''
    })

    const dialogForm = ref({
      show: false,
      mode: 'new',
      data: ref({})
    })

    const dialogConfirm = ref({
      show: false,
      title: 'Wait',
      body: 'This can\'t be undone! Proceed?'
    })

    const dialogPreview = ref({
      show: false,
      type: 'image',
      source: ''
    })

    const filter = ref('')

    const columns = ref([
      { name: 'action', label: '', align: 'left', field: '' },
      { name: 'title', label: 'Title', align: 'left', field: 'title', sortable: true },
      { name: 'description', label: 'Description', align: 'left', field: 'description', sortable: true, format: val => limitText(val, 100, true) },
      { name: 'type', label: 'Type', align: 'left', field: 'type', sortable: true, format: val => capitalize(val, 100, true) },
      { name: 'url', label: 'Url', align: 'left', field: 'url', sortable: true, format: val => limitText(val, 100, true) },
      { name: 'createdAt', label: 'Created At', field: 'createdAt', sortable: true, format: val => formatDate(val, 'DD-MMM-YYYY HH:mm') },
      { name: 'updatedAt', label: 'Updated At', field: 'updatedAt', sortable: true, format: val => formatDate(val, 'DD-MMM-YYYY HH:mm') }
    ])

    const projects = computed(() => store.state.projects)
    const rows = ref([])
    const mainId = ref('')
    const projectTitle = ref('')

    watchEffect(() => {
      mainId.value = route.params?.id
      if (mainId.value) {
        const find = projects.value.find(e => e?.id === mainId.value)
        rows.value = find?.medias ?? []
        projectTitle.value = find?.title
      }
    })

    const selectedRow = ref({})

    const onSelectRow = async (mode, selected) => {
      selectedRow.value = selected
      if (mode === 'edit') {
        dialogForm.value.show = true
        dialogForm.value.mode = mode
        dialogForm.value.data = selected
      } else {
        dialogConfirm.value.show = true
      }
    }

    const onSelectMedia = async (selected) => {
      dialogPreview.value.show = true
      dialogPreview.value.type = selected?.type
      dialogPreview.value.source = selected?.url
      console.log(dialogPreview.value)
    }

    const onDelete = async () => {
      await self.$api.projectMedia.delete(mainId.value, selectedRow.value?.id)
      await store.dispatch('setProfileFull')
      dialogConfirm.value.show = false
      dialog.value.show = true
      dialog.value.message = 'Delete project media success'
    }

    const onCreate = () => {
      dialogForm.value.show = true
      dialogForm.value.mode = 'new'
      dialogForm.value.data = {
        title: '',
        description: '',
        type: '',
        url: ''
      }
    }

    return {
      columns,
      rows,
      onSelectRow,
      dialog,
      filter,
      dialogForm,
      onCreate,
      mainId,
      router,
      projectTitle,
      dialogConfirm,
      onDelete,
      dialogPreview,
      onSelectMedia
    }
  }
}
</script>
